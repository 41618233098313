import React from 'react'
import { graphql } from 'gatsby'
import { createGlobalStyle } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { css } from '@styled-system/css'
import Box from './Box'
import Flex from './Flex'
import Section from './Section'
import Container from './Container'
import Text from './Text'
import Heading from './Heading'
import Seo from './Seo'
import Link from './Link'

const GlobalStyle = createGlobalStyle`
  .BlogPost {
    h1 {
      font-size: ${themeGet('fontSizes.8')}px;
    }
    h2 {
      font-size: ${themeGet('fontSizes.7')};
    }
    h3 {
      font-size: ${themeGet('fontSizes.6')};
    }

    p,ul,li,ol,blockquote,pre,table,th,td,tr,dl,dt,dd,form,fieldset,legend,address {
      font-size: ${themeGet('fontSizes.5')};
      line-height: 3.2rem;
      color: ${themeGet('colors.gray.100')};
    }

    a {
      color: ${themeGet('colors.gray.100')};
      text-decoration: underline;
      transition: color 0.2s ease;
    }
    a:hover {
      color: ${themeGet('colors.gray.300')};
    }

    h1,h2,h3,h4,h5,h6 {
      margin-top: ${themeGet('space.5')}px;
      margin-bottom: 0;
    }

    p, ul, ol, blockquote, table, dl, dt, dd,
    .gatsby-highlight pre {
      margin-top: ${themeGet('space.4')}px;
      margin-bottom: 0;
    }

    blockquote {
      margin-right: ${themeGet('space.4')}px;
      margin-left: ${themeGet('space.4')}px;
      font-style: italic;
    }

    img {
      max-width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
`

const BlogPostTemplate = ({ data, pageContext, location, ...props }) => {
  const { markdownRemark, allJson } = data

  const { html, frontmatter } = markdownRemark
  const { title, description, date, products } = frontmatter

  const { edges } = allJson
  const allProducts = edges.map(({ node }) => node)

  const featuredProducts = products
    ? allProducts.filter(({ productId }) => products.includes(productId))
    : []

  return (
    <>
      <GlobalStyle />

      <Seo
        title={title}
        description={description}
        pathname={location.pathname}
      />

      <Section>
        <Container>
          <Flex as='header' flexDirection='column' alignItems='center' mb='5'>
            <Heading
              as='h2'
              mb='3'
              fontFamily='heading'
              fontSize='8'
              textAlign='center'
            >
              {title}
            </Heading>

            <Text fontSize='5' color='gray.300'>
              {date}
            </Text>
          </Flex>

          {featuredProducts.length > 0 && (
            <Box as='section'>
              <Heading as='h3' fontSize='6' mb='2'>
                Featured Products
              </Heading>

              <Text fontSize='4' fontStyle='italic' color='gray.300' mb='5'>
                *As an Amazon Associate I earn from qualifying purchases.
              </Text>

              <Flex
                flexWrap='nowrap'
                overflow='auto'
                mx='-16px'
                css={css({
                  '-webkit-overflow-scrolling': 'touch',
                  '-ms-overflow-style': '-ms-autohiding-scrollbar',
                })}
              >
                {featuredProducts.map(({ productId, name, link, images }) => (
                  <Box key={productId} width={[1, 1 / 2, 1 / 3]} mb='4' px='3'>
                    <Link href={link} target='_blank' rel='noopener noreferrer'>
                      <Box
                        as='img'
                        src={images[0]}
                        alt={name}
                        css={css({
                          width: '100%',
                          height: 'auto',
                          objectFit: 'cover',
                          borderRadius: '24px',
                        })}
                      />
                    </Link>

                    <Box mt='2' px='2'>
                      <Text fontSize='1.4rem' color='gray.300' mb='2'>
                        {name}
                      </Text>

                      <Link
                        fontSize='1.4rem'
                        href={link}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        🇺🇲 {link}
                      </Link>
                    </Box>
                  </Box>
                ))}
              </Flex>
            </Box>
          )}

          <Box
            as='article'
            className='BlogPost'
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Container>
      </Section>
    </>
  )
}

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        products
      }
    }

    allJson {
      totalCount
      edges {
        node {
          productId
          name
          link
          categories
          images
        }
      }
    }
  }
`

export default BlogPostTemplate
